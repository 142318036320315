import api from '../api';
import Cookies from 'js-cookie';

type SignInEmailData = {
  email: string;
  password: string;
};

type SignInMicrosoftData = {
  microsoftToken: string;
};

type ForgotPasswordData = {
  email: string;
};

export const signInByEmail = async ({ email, password }: SignInEmailData) => {
  const signInResponse = await api.post('/authentication', {
    email,
    password,
    strategy: 'local',
  });
  if (signInResponse.status == 201) {
    return {
      token: signInResponse.data.accessToken,
      userId: signInResponse.data.user,
    };
  } else {
    return { token: null, userId: null };
  }
};

export const signInByMicrosoft = async ({
  microsoftToken,
}: SignInMicrosoftData) => {
  let msLogged = await Cookies.get('msLogged');
  if (msLogged == 'auto') {
    try {
      const signInResponse = await api.post('/authentication', {
        accessToken: microsoftToken,
        strategy: 'microsoft',
      });
      if (signInResponse.status == 201) {
        return {
          token: signInResponse.data.accessToken,
          userId: signInResponse.data.user,
        };
      } else {
        return { token: null, userId: null };
      }
    } catch (error) {
      return { token: null, userId: null };
    }
  } else {
    return { token: null, userId: null };
  }
};

export const sendForgotPassword = async ({ email }: ForgotPasswordData) => {
  try {
    const forgotResponse = await api.post(`/forgot-password`, {
      email,
    });
    if (forgotResponse.status === 201) {
      return {
        sent: forgotResponse.data.sent,
      };
    }
  } catch (error) {
    return {
      sent: 'false',
    };
  }
};

export default {};
