import React, { useContext, useState, useEffect, useRef } from 'react';
import {
  AzureAD,
  AuthenticationState,
  IAzureADFunctionProps,
} from 'react-aad-msal';
import { Form, Formik, ErrorMessage, Field } from 'formik';
import AuthContext from '../../contexts/auth';
import * as Yup from 'yup';
import { authProvider } from '../../constants/msConfig';
import { useCookies } from 'react-cookie';
import ReactLoading from 'react-loading';
import { Link } from 'react-router-dom';
import { isMobile } from 'react-device-detect';

declare global {
  namespace JSX {
    interface IntrinsicElements {
      'layers-auth-button': any;
    }
  }
}

const SignInSchema = Yup.object().shape({
  email: Yup.string()
    .email('Email inválido.')
    .required('Necessário o preenchimento do email.'),
  password: Yup.string()
    .min(6, 'Senha precisa ter pelo menos 6 caracteres.')
    .required('Senha é necessário ser preenchido.'),
});

const Index: React.FC = () => {
  const [isLogging, setIsLogging] = useState<boolean>(false);
  const [error, setError] = useState<boolean>(false);
  const [errorMicrosoft, setErrorMicrosoft] = useState<boolean>(false);
  const [errorLayers, setErrorLayers] = useState<boolean>(false);
  const [msAuthenticationState, setMsAuthenticationState] = useState<
    string | null
  >(null);
  const [cookies, setCookie] = useCookies(['msLogged']);

  const layersAuthRef = useRef<HTMLButtonElement>();

  const { signInEmail, signOut, isAuthenticated, signInMicrosoft } =
    useContext(AuthContext);

  useEffect(() => {
    if (cookies.msLogged == 'auto') {
      if (msAuthenticationState == 'Authenticated') {
        handleLoginByMicrosoft();
      }
    }
  }, [msAuthenticationState]);

  useEffect(() => {
    console.log('layersAuthRef', layersAuthRef);
  }, [layersAuthRef]);

  async function handleLoginByMicrosoft() {
    setIsLogging(true);
    const token = await authProvider.getAccessToken();
    let signResponse = await signInMicrosoft({
      microsoftToken: token.accessToken,
    });
    setErrorMicrosoft(!signResponse);
    setIsLogging(false);
  }

  async function handleLogin(email: string, password: string) {
    setIsLogging(true);
    let signResponse = await signInEmail({
      email: email,
      password: password,
    });
    setError(!signResponse);
    setIsLogging(false);
  }

  return (
    <div
      style={{
        display: 'flex',
        flex: 1,
        minHeight: '100vh',
        flexDirection: 'column',
        alignItems: 'center',
        backgroundImage: "url('/images/login_background.png')",
        backgroundPosition: 'bottom center',
        backgroundRepeat: 'repeat',
      }}
    >
      <Formik
        initialValues={{ email: '', password: '' }}
        validationSchema={SignInSchema}
        onSubmit={async (values, { setSubmitting }) => {
          await handleLogin(values.email, values.password);
          setSubmitting(false);
        }}
      >
        {({ isSubmitting }) => (
          <Form
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center',
              color: '#fff',
              backgroundColor: '#6797B7',
              padding: isMobile ? '20px' : '60px 40px 40px 40px',
              borderRadius: 16,
              marginTop: 100,
              marginBottom: 100,
            }}
          >
            {(isLogging || isSubmitting) && (
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  position: 'absolute',
                  top: 0,
                  left: 0,
                  width: '100vw',
                  height: '100vh',
                  opacity: 0.5,
                  backgroundColor: '#000',
                }}
              >
                <ReactLoading
                  type={'spinningBubbles'}
                  color={'#fff'}
                  height={'8%'}
                  width={'8%'}
                />
              </div>
            )}
            <img
              style={{ width: 105, height: 78, marginBottom: 45 }}
              src="/images/logo.png"
            />
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'flex-start',
              }}
            >
              <label
                style={{ fontWeight: 'bold', fontSize: 16, marginBottom: 5 }}
              >
                LOGIN
              </label>
              <Field
                style={{
                  height: 52,
                  width: 282,
                  borderRadius: 8,
                  paddingLeft: 10,
                  fontSize: 20,
                }}
                type="email"
                name="email"
              />
            </div>
            <div style={{ marginTop: 10, marginBottom: 10, color: 'red' }}>
              <ErrorMessage name="email" component="div" />
            </div>
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'flex-start',
              }}
            >
              <label
                style={{ fontWeight: 'bold', fontSize: 16, marginBottom: 5 }}
              >
                SENHA
              </label>
              <Field
                style={{
                  height: 52,
                  width: 282,
                  borderRadius: 8,
                  paddingLeft: 10,
                  fontSize: 20,
                }}
                type="password"
                name="password"
              />
            </div>
            <div style={{ marginTop: 15, marginBottom: 15, color: 'red' }}>
              <ErrorMessage name="password" component="div" />
            </div>

            <div
              style={{
                width: 282,
                display: 'flex',
                alignItems: 'flex-start',
                justifyContent: 'flex-start',
                textAlign: 'left',
                textDecoration: 'underline',
                fontSize: 16,
                fontWeight: 'bold',
                marginBottom: 25,
              }}
            >
              <Link to="/esqueci-minha-senha">ESQUECI MINHA SENHA</Link>
            </div>
            {error == true && (
              <div style={{ marginTop: 15, marginBottom: 15, color: 'red' }}>
                Email e/ou senha inválido
              </div>
            )}
            <button
              style={{
                color: '#fff',
                height: 52,
                width: 282,
                borderRadius: 8,
                backgroundColor: '#25354C',
                fontSize: 15,
                fontWeight: 'bold',
              }}
              type="submit"
              disabled={isSubmitting}
            >
              ENTRAR
            </button>
            <div style={{ marginTop: 40, fontWeight: 'bold', fontSize: 16 }}>
              ENTRAR COM MINHA CONTA
            </div>
            <div
              style={{
                display: 'flex',
                width: 200,
                height: 120,
                alignItems: 'center',
                justifyContent: 'space-between',
              }}
            >
              <div
                style={{
                  display: 'flex',
                  width: 88,
                  height: 102,
                }}
              >
                <button
                  type="button"
                  style={{ backgroundColor: 'transparent', padding: 0 }}
                  onClick={async () => {
                    if (layersAuthRef !== null) {
                      layersAuthRef?.current?.click();
                    }
                  }}
                >
                  <img src="/images/login_layers.png" alt="" />
                  <layers-auth-button
                    hidden={true}
                    ref={layersAuthRef}
                    client-id="mundo-z-escola-dev"
                    scope="identity:basic:read"
                    mode="popup"
                    label="Layers"
                  ></layers-auth-button>
                </button>
              </div>
              <div
                style={{
                  display: 'flex',
                  width: 88,
                  height: 102,
                }}
              >
                <AzureAD provider={authProvider}>
                  {({ login, authenticationState }: IAzureADFunctionProps) => {
                    // loginMs = login;
                    if (
                      authenticationState === AuthenticationState.InProgress
                    ) {
                      setMsAuthenticationState('inProgress');
                    }
                    if (
                      authenticationState === AuthenticationState.Authenticated
                    ) {
                      setMsAuthenticationState('Authenticated');
                    }
                    if (
                      authenticationState ===
                      AuthenticationState.Unauthenticated
                    ) {
                      setMsAuthenticationState('Unauthenticated');
                    }
                    return (
                      <button
                        type="button"
                        style={{ backgroundColor: 'transparent', padding: 0 }}
                        onClick={async () => {
                          await setCookie('msLogged', 'auto');
                          login();
                        }}
                      >
                        <img src="/images/login_microsoft.png" alt="" />
                      </button>
                    );
                  }}
                </AzureAD>
              </div>
            </div>
            <div>
              {errorMicrosoft && (
                <div style={{ marginTop: 15, marginBottom: 15, color: 'red' }}>
                  Erro ao logar com a microsoft
                </div>
              )}

              {errorLayers && (
                <div style={{ marginTop: 15, marginBottom: 15, color: 'red' }}>
                  Erro ao logar com o layers
                </div>
              )}
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default Index;
