import React, { useContext } from 'react';
import AuthContext from '../../contexts/auth';

const Index = () => {
  const { signOut, isAuthenticated, userId, user } = useContext(AuthContext);

  async function handleLogoff() {
    signOut();
  }

  return (
    <div className="bg-gray-100">
      {/* {isAuthenticated && <button onClick={handleLogoff}>Logoff</button>} */}
    </div>
  );
};

export default Index;
