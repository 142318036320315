import React from 'react';

const MenuItem: React.FC<{
  name: string;
  icon: string;
  label: string;
  onClick: (id: string) => void;
}> = ({ name, icon, label, onClick }) => {
  return (
    <div
      onClick={() => {
        onClick(name);
      }}
      className="flex flex-row items-center mb-4 rounded cursor-pointer"
    >
      <div style={{ width: 53, height: 50 }} className="flex mr-3 ">
        <img src={icon} />
      </div>
      <div className="flex flex-1 mt-1 text-sm font-bold text-matisse">
        {label}
      </div>
    </div>
  );
};
export default MenuItem;
