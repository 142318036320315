import React, { useContext } from 'react';
import AuthContext from '../contexts/auth';

import AdminRoutes from './AdminRoutes';
import LoginRoutes from './LoginRoutes';

const Routes = () => {
  const { isAuthenticated } = useContext(AuthContext);
  if (isAuthenticated == null) {
    return <div>loading</div>;
  } else if (isAuthenticated == true) {
    return <AdminRoutes />;
  } else {
    return <LoginRoutes />;
  }
};

export default Routes;
