import React, { useContext, useState, useEffect } from 'react';
import AuthContext from '../../contexts/auth';
import ProfileHeader from './ProfileHeader';
import TextInput from '../Inputs/TextInput';

const Header = () => {
  const { user, getAndRefreshMe } = useContext(AuthContext);
  const [loading, setLoading] = useState<boolean>(true);
  console.log('user', user);

  useEffect(() => {
    getAndRefreshMe();
  }, []);

  useEffect(() => {
    if (user == null) {
      setLoading(true);
    } else {
      setLoading(false);
    }
  }, [user]);

  return (
    <div
      className={`flex flex-row items-center ${
        loading ? 'justify-center' : 'justify-between'
      } h-32 lg:h-36`}
    >
      {loading && <div></div>}
      {loading == false && (
        <>
          <ProfileHeader
            name={user == null ? '' : user.email}
            photo={user == null ? '' : user.photo}
          />
          <div className="h-10 mx-5 lg:h-auto lg:mx-0">
            <img className="h-10 lg:h-auto" src="/images/logo_header.png" />
          </div>
          <div className="w-32 md:w-48 lg:w-64">
            <TextInput icon="/images/icon_search.png" name="search" />
          </div>
        </>
      )}
    </div>
  );
};
export default Header;
