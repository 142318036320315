import React from 'react';
import { BrowserRouter, Route, Redirect } from 'react-router-dom';

import Admin from '../pages/Admin/index';
import Layout from '../layouts';

const AdminRoutes = () => {
  return (
    <Layout>
      <BrowserRouter>
        <Route path="/admin" exact component={Admin} />
        <Redirect to="/admin" />
      </BrowserRouter>
    </Layout>
  );
};

export default AdminRoutes;
