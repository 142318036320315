import React from 'react';

const ProfileHeader: React.FC<{ name: string; photo?: string }> = ({
  name,
  photo,
}) => {
  return (
    <div className="flex flex-row ">
      <div className="flex items-center justify-center w-16 h-16 lg:w-32 lg:h-32 rounded-xl bg-heather">
        <img
          className="h-10 w-7 lg:w-12 lg:h-16"
          // style={{ width: 50, height: 72 }}
          src={'/images/no_profile_photo.png'}
        />
      </div>
      <div className="flex flex-col justify-end ml-3 text-sm lg:ml-12 lg:text-2xl text-rhino">
        <div className="font-bold">OLÁ</div>
        <div>ZOOMMER</div>
      </div>
    </div>
  );
};
export default ProfileHeader;
