import React, {
  createContext,
  ReactNode,
  useCallback,
  useEffect,
  useState,
} from 'react';
import { useCookies } from 'react-cookie';
import api from '../api';
import {
  signInByEmail,
  signInByMicrosoft,
  sendForgotPassword,
} from '../services/auth';
import decode from 'jwt-decode';
import { getMe, getUserId } from '../services/users';

const AuthContext = createContext({} as AuthContextType);

type SignInEmailData = {
  email: string;
  password: string;
};

type SignInMicrosoftData = {
  microsoftToken: string;
};

type AuthContextType = {
  isAuthenticated: boolean | null;
  userId: string | null;
  user: IMe | null;
  signInEmail: (data: SignInEmailData) => Promise<boolean>;
  signInMicrosoft: (data: SignInMicrosoftData) => Promise<boolean>;
  sendEmailForgotPassword: (email: string) => Promise<boolean>;
  signOut: () => void;
  getAndRefreshMe: () => void;
};

type IAuthProvider = {
  children: ReactNode;
};

type IMe = {
  _id: string;
  email: string;
  photo: string;
};

export const AuthProvider = ({ children }: IAuthProvider) => {
  const [userData, setUserData] = useState<string | null>(null);
  const [user, setUser] = useState<IMe | null>(null);
  const [isAuthenticated, setIsAuthenticated] = useState<boolean | null>(null);
  const [cookies, setCookie, removeCookie] = useCookies(['token']);

  useEffect(() => {
    checkAuthenticated();
  }, [cookies]);

  const sendEmailForgotPassword = useCallback(async (email: string) => {
    const sendForgotPasswordResponse = await sendForgotPassword({ email });
    return sendForgotPasswordResponse?.sent === 'ok';
  }, []);

  const signInEmail = useCallback(
    async ({ email, password }: SignInEmailData) => {
      const signInResponse = await signInByEmail({ email, password });
      if (signInResponse.token !== null) {
        setUserData(signInResponse.userId);
        setCookie('token', signInResponse.token);
        return true;
      } else {
        return false;
      }
    },
    []
  );

  const signInMicrosoft = useCallback(
    async ({ microsoftToken }: SignInMicrosoftData) => {
      const signInResponse = await signInByMicrosoft({ microsoftToken });
      if (signInResponse.token !== null) {
        setUserData(signInResponse.userId._id);
        setCookie('token', signInResponse.token);
        return true;
      } else {
        return false;
      }
    },
    []
  );

  const signOut = useCallback(() => {
    setUserData(null);
    removeCookie('token');
    removeCookie('msLogged');
  }, []);

  const checkAuthenticated = async () => {
    if (cookies.token) {
      if (userData == null) {
        const userId = await getUserId();
        setUserData(userId);
        getAndRefreshMe();
      }
      api.defaults.headers.common['Authorization'] = `Bearer ${cookies.token}`;
      setIsAuthenticated(true);
    } else {
      setIsAuthenticated(false);
    }
  };

  const getAndRefreshMe = async () => {
    try {
      let _user = await getMe();
      setUser(_user);
    } catch (error) {
      console.log('error to getMe ');
    }
  };

  return (
    <AuthContext.Provider
      value={{
        isAuthenticated,
        signInEmail,
        signInMicrosoft,
        signOut,
        sendEmailForgotPassword,
        getAndRefreshMe,
        userId: userData,
        user: user,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export default AuthContext;
