import { BrowserRouter, Route, Redirect } from 'react-router-dom';

import Login from '../pages/Login';
import ForgotPassword from '../pages/ForgotPassword';

const LoginRoutes = () => {
  return (
    <BrowserRouter>
      <Route path="/" exact component={Login} />
      <Route path="/esqueci-minha-senha" exact component={ForgotPassword} />
      <Redirect to="/" />
    </BrowserRouter>
  );
};

export default LoginRoutes;
