import React, { useContext, useState, useEffect } from 'react';
import MenuItem from './MenuItem';

const SideMenu = () => {
  const [opened, setOpened] = useState<boolean>(true);

  const menuItems = [
    {
      name: 'zoommers',
      icon: '/images/menu_item_zoommers.png',
      label: 'ZOOMMERS',
    },
    {
      name: 'franchisees',
      icon: '/images/menu_item_franquias.png',
      label: 'FRANQUIAS',
    },
    {
      name: 'schools',
      icon: '/images/menu_item_escolas.png',
      label: 'DADOS DA ESCOLA',
    },
    {
      name: 'programs',
      icon: '/images/menu_item_acomp.png',
      label: 'ACOMPANHAMENTO DOS PROGRAMAS',
    },
    {
      name: 'production',
      icon: '/images/menu_item_prod.png',
      label: 'PRODUÇÃO ZOOM',
    },
    {
      name: 'admin',
      icon: '/images/menu_item_adm.png',
      label: 'ADMINISTRAÇÃO',
    },
  ];

  const handleClick = (name: string) => {
    console.log('clicked', name);
  };

  return (
    <div className="fixed w-full max-w-xs lg:static mr-9">
      <div
        className={`flex flex-row h-auto py-6 rounded-xl lg:w-full bg-heather transform lg:translate-x-0 lg:ml-0 ${
          opened
            ? 'translate-x-0 ease-out duration-700 -ml-4'
            : '-translate-x-full ease-in duration-700 ml-4'
        } `}
      >
        <div className="flex flex-col flex-1 h-full px-10">
          {menuItems.map((menuItem) => (
            <MenuItem
              onClick={(name: string) => {
                console.log('change to', name);
              }}
              name={menuItem.name}
              icon={menuItem.icon}
              label={menuItem.label}
            />
          ))}
        </div>
        <div className="flex items-center justify-center ">
          <div
            onClick={() => {
              setOpened(!opened);
            }}
            className="flex items-center justify-center w-4 h-16 mr-2 rounded lg:hidden bg-matisse"
          >
            <img
              className={`transform duration-700 ${
                opened ? 'rotate-0' : 'rotate-180'
              }`}
              src="/images/menu_arrow.png"
            />
          </div>
        </div>
      </div>
    </div>
  );
};
export default SideMenu;
