import React, { InputHTMLAttributes } from 'react';

interface InputProps extends InputHTMLAttributes<HTMLInputElement> {
  name: string;
  width?: number | string;
  icon?: string;
}

const TextInput: React.FC<InputProps> = (props) => {
  return (
    <div
      style={{ width: props.width ? props.width : '100%' }}
      className="flex flex-row items-center h-12 px-4 bg-white rounded"
    >
      {props.icon && (
        <div className="flex items-center justify-center">
          <img src={props.icon} />
        </div>
      )}
      <input
        className={`flex pt-2 align-middle items-center justify-center h-12 w-full text-rhino text-xl ${
          props.icon ? 'px-4' : ''
        }`}
        type="text"
        name={props.name}
        placeholder={props.placeholder}
      />
    </div>
  );
};
export default TextInput;
