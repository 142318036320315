import api from '../api';
import Cookies from 'js-cookie';
import decode from 'jwt-decode';

type JwtDecodeToken = {
  sub: string;
};

type IMe = {
  _id: string;
  email: string;
  photo: string;
};

export const getUserId = async (): Promise<string | null> => {
  const token = await Cookies.get('token');
  let userId = null;
  if (token != undefined) {
    const decodedToken = await decode<JwtDecodeToken>(token);
    userId = decodedToken.sub;
  }
  return userId;
};

export const getMe = async (): Promise<IMe | null> => {
  const userId = await getUserId();
  const getMeResponse = await api.get(`/users/${userId}`);
  if (getMeResponse.status == 200) {
    return {
      _id: getMeResponse.data._id,
      email: getMeResponse.data.email,
      photo: getMeResponse.data.photo,
    };
  } else {
    return null;
  }
};

export default {};
