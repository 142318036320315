import React from 'react';
import Header from '../components/Header/index';
import SideMenu from '../components/SideMenu/index';

const AdminLayout: React.FC = (props) => {
  return (
    <div className="flex justify-center w-full min-h-screen px-3 py-3 bg-catskill lg:py-6 lg:px-10">
      <div className="flex flex-col w-full max-w-screen-hd">
        <Header />
        <div className="flex flex-row mt-5">
          <SideMenu />
          <div className="flex flex-col flex-1 w-full p-6 ml-6 bg-seashell rounded-xl lg:ml-0">
            {props.children}
          </div>
        </div>
      </div>
    </div>
  );
};
export default AdminLayout;
